import { forwardRef, memo } from 'react';
import { cn, Price } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import styles from './Section.module.css';

import type { HTMLAttributes, ReactChild } from 'react';

enum TitleTags {
  universal = 'div',
  main = 'h1',
  secondary = 'h2',
}

export type TypeSection = keyof typeof TitleTags;

export interface SectionProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  className?: string;
  title?: ReactChild;
  arrows?: ReactChild | ReactChild[];
  additional?: ReactChild | ReactChild[];
  priceMin?: number;
  description?: ReactChild | ReactChild[];
  tabs?: ReactChild | ReactChild[];
  cnDescription?: string;
  typeSection?: TypeSection;
}

const Section = forwardRef<HTMLDivElement, SectionProps>((props, ref) => {
  const {
    className,
    title,
    arrows,
    additional,
    priceMin,
    description,
    tabs,
    children,
    cnDescription,
    typeSection = 'secondary', // По требованию SEO теги должны быть разными в зависимости от страницы и страны
    ...restProps
  } = props;
  const { isDesktop } = useMedias();
  const TitleContainer = TitleTags[typeSection];

  return (
    <div {...restProps} className={cn(styles.section, className)} ref={ref}>
      <div className={styles.head}>
        <div className={styles.headContent}>
          {typeof title === 'string' && (
            <TitleContainer className={styles.title}>{title}</TitleContainer>
          )}
          {typeof title === 'object' && <div className={styles.title}>{title}</div>}

          {priceMin && (
            <div className={styles.priceMin}>
              {`от `}
              <Price price={priceMin} />
            </div>
          )}

          {description && (
            <div className={cn(styles.description, cnDescription)}>{description}</div>
          )}
          {tabs && <div>{tabs}</div>}
        </div>

        {arrows && !isDesktop && <div className={styles.arrows}>{arrows}</div>}
        {additional && <div className={styles.additional}>{additional}</div>}
      </div>

      <div>{children}</div>
    </div>
  );
});

Section.displayName = 'Section';

export default memo(Section);
