import { cn, GalleryWithProgress } from '@divlab/divanui';

import Filter from './Filter';
import styles from './CollectionFilters.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { FiltersValue } from '@Types/Filters';

interface CollectionFiltersProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  values: FiltersValue[];
}

const CollectionFilters: FC<CollectionFiltersProps> = (props) => {
  const { className, values, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.wrapper, className)}>
      <GalleryWithProgress cnGallery={styles.gallery} cnTrack={styles.track} centered emptyTrack>
        {values.map((value) => (
          <div key={value.name}>
            <Filter value={value} />
          </div>
        ))}
      </GalleryWithProgress>
    </div>
  );
};

export default CollectionFilters;
