import { memo, useCallback, useRef, useState } from 'react';
import { cn } from '@divlab/divanui';

import ServicePageTitle from '@Components/ServicePageTitle';
import BreadcrumbsWrapper from '@Components/BreadcrumbsWrapper';
import InformationTabsNavigation from '@Components/InformationTabsNavigation';
import useScrollPosition from '@Hooks/useScrollPosition';
import styles from './PageTopBar.module.css';

import type { FC, ReactElement } from 'react';
import type { BreadcrumbData } from '@Types/Breadcrumbs';
import type { NavigationItemData } from '@Types/NavigationItem';
import type { CategoryBannerData } from '@Promo/typings';

export interface PageTopBarProps {
  breadcrumbs?: BreadcrumbData[];
  breadcrumbsSeoData?: unknown;
  title?: string;
  navigation?: NavigationItemData[];
  banner?: ReactElement<{ banner?: CategoryBannerData }>;
  titleAlign?: 'leftOnMobile';
}

const PageTopBar: FC<PageTopBarProps> = (props) => {
  const {
    breadcrumbs = [],
    title,
    navigation = [],
    banner,
    breadcrumbsSeoData,
    titleAlign,
  } = props;
  const [hidden, setHidden] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const hasNavigation = navigation.length > 0;
  const refContent = useRef<HTMLDivElement>(null);
  const refBreadCrumbs = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!refContent.current || !banner) return;
    const { top } = refContent.current.getBoundingClientRect();
    const { y: borderOfBreadCrumbs } = refBreadCrumbs.current.getBoundingClientRect();
    setScrolling(borderOfBreadCrumbs < 0);
    setHidden(top < 0);
  }, [banner]);

  useScrollPosition(handleScroll);

  const bannerTempate = banner?.props.banner?.template;
  const wideBanner = bannerTempate === 'wide' || bannerTempate === 'yandex';

  return (
    <>
      <div className={styles.mainWrapper} ref={refBreadCrumbs}>
        <BreadcrumbsWrapper
          className={styles.breadcrumbs}
          breadcrumbs={breadcrumbs}
          breadcrumbsSeoData={breadcrumbsSeoData}
        />
      </div>

      {banner && (
        <>
          <div
            className={cn(styles.banner, {
              [styles.mainWrapper]: !wideBanner,
              [styles.hidden]: hidden,
              [styles.scrolling]: scrolling,
            })}
          >
            {banner}
          </div>
          <div className={styles.bannerHelper}></div>
        </>
      )}

      <div className={cn(styles.content, { [styles.withBanner]: banner })} ref={refContent}>
        {(title || hasNavigation) && (
          <div className={styles.mainWrapper}>
            {title && !banner && <ServicePageTitle title={title} titleAlign={titleAlign} />}
            {hasNavigation && (
              <InformationTabsNavigation className={styles.menu} navigation={navigation} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(PageTopBar);
