import { memo, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import Img from '@UI/Img';
import UILink from '@UI/Link';
import Link from '@Navigation/Link';
import { useDeps } from '@Contexts/DI';
import defaultIcon from './icons/default.svg';
import styles from './ConstructorStub.module.css';

import type { ConstructorStubData } from '@Types/Category';
import type { FC, HTMLAttributes } from 'react';

export interface ConstructorStubProps extends HTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
  className?: string;
  stub: ConstructorStubData;
  productId: number;
}

const Container: FC<ConstructorStubProps> = (props) => {
  const { productId, children, stub, ...restProps } = props;
  const configurationId = stub?.configurator?.configurationId;
  const isRibbl = stub.classifications?.includes('ribble');
  const isBrera = stub.classifications?.includes('brera');
  const { analytics } = useDeps();

  const handleOpenConstructor = useCallback(() => {
    if (isRibbl || isBrera) {
      analytics.dispatchEvent('constructor.clickToOpen', {
        source: stub.classifications[0] || 'ribble',
      });

      ModalWindows.open('ModuleConstructor', {
        configurationId,
        code: stub.classifications[0] || 'ribble',
        source: stub.classifications[0] || 'ribble',
      });

      return;
    }

    analytics.dispatchEvent('constructor.clickToOpen', {
      source: 'Категория товара',
    });

    ModalWindows.open('Constructor', { productId, source: 'Категория товара' });
  }, [isRibbl, isBrera, analytics, productId, stub.classifications, configurationId]);

  return stub.link ? (
    <Link {...restProps} to={stub.link}>
      {children}
    </Link>
  ) : (
    <div {...restProps} onClick={handleOpenConstructor}>
      {children}
    </div>
  );
};

const ConstructorStub: FC<ConstructorStubProps> = (props) => {
  const { className, stub, ...restProps } = props;

  return (
    <Container {...restProps} stub={stub} className={cn(styles.constructorStub, className)}>
      <div className={styles.iconWrapper}>
        {stub.promo?.text && <div className={styles.discount}>{stub.promo.text}</div>}
        <Img
          className={styles.icon}
          cnImage={styles.iconImg}
          src={stub.icon ? stub.icon : defaultIcon}
          alt={stub.alt}
        />
      </div>

      <div className={styles.linkWrapper}>
        <UILink view='primary' underlined>
          {stub.title}
        </UILink>
      </div>
    </Container>
  );
};

export default memo(ConstructorStub);
