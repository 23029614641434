import { memo, useMemo } from 'react';
import { cn, Gallery } from '@divlab/divanui';

import Link from '@Navigation/Link';
import IconArrowTriple from './elements/ArrowTriple';
import styles from './InformationTabsNavigation.module.css';

import type { NavigationItemData } from '@Types/NavigationItem';
import type { FC, HTMLAttributes } from 'react';

export interface InformationTabsNavigationProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  navigation: NavigationItemData[];
}

const icons = {
  arrowTriple: IconArrowTriple,
};

const InformationTabsNavigation: FC<InformationTabsNavigationProps> = (props) => {
  const { navigation, ...restProps } = props;

  const slideIndex = useMemo(() => {
    return navigation.findIndex((item) => item.active);
  }, [navigation]);

  return (
    <nav {...restProps}>
      <div className={styles.galleryWrapper}>
        <Gallery slideIndex={slideIndex} className={styles.gallery} centered>
          {navigation.map((item) => {
            const IconBefore = icons[item.before];

            return (
              <div className={styles.navigationItem} key={item.href} data-testid={item.testId}>
                <Link
                  to={item.href}
                  className={cn(styles.navigationLink, { [styles.active]: item.active })}
                >
                  {!!IconBefore && (
                    <IconBefore
                      active={item.active}
                      className={cn({
                        [styles.arrowTriple]: item.before === 'arrowTriple',
                      })}
                    />
                  )}
                  {item.name}
                </Link>
              </div>
            );
          })}
        </Gallery>
      </div>
    </nav>
  );
};

export default memo(InformationTabsNavigation);
