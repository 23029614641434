import { memo, useMemo } from 'react';

import * as CatalogFiltrator from '@Stores/CatalogFiltrator';
import DefaultCatalog from '@Components/DefaultCatalog';
import useNavigation from '@Navigation/useNavigation';

import type { DefaultCatalogProps } from '@Components/DefaultCatalog';
import type { FC } from 'react';

export type DynamicCatalogProps = DefaultCatalogProps;

const DynamicCatalog: FC<DynamicCatalogProps> = (props) => {
  const { slug, category, rubrics, ...restProps } = props;
  const [firstPage] = category.data?.pages || [];
  const { search } = useNavigation();
  const totalCount = firstPage.activeProductsTotalCount || firstPage.productsTotalCount;

  const filtersValues = useMemo(() => {
    const { filters } = firstPage;

    return {
      parameters: filters?.parameters || {},
      parameterValues: filters?.parameterValues || [],
      filters: filters?.filters || [],
      sort: filters?.sort || [],
      groupBy: filters?.groupBy || [],
    };
  }, [firstPage]);

  CatalogFiltrator.init({
    ...firstPage.filters,
    ...filtersValues,
    categories: (rubrics || []).flat(),
    totalCount,
    countOfPriorityFilters: firstPage.countOfPriorityFilters,
    search,
  });

  return <DefaultCatalog {...restProps} category={category} slug={slug} rubrics={rubrics} />;
};

export default memo(DynamicCatalog);
