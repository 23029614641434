import type { FC, SVGAttributes } from 'react';

interface IconArrowTripleProps extends SVGAttributes<SVGSVGElement> {
  className?: string;
  active?: boolean;
}

const IconArrowTriple: FC<IconArrowTripleProps> = (props) => {
  const { active, ...restProps } = props;
  return (
    <svg
      {...restProps}
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.3988 15.9741C19.697 15.8647 19.85 15.5344 19.7406 15.2363L17.9584 10.3779C17.849 10.0797 17.5186 9.9267 17.2205 10.0361C16.9224 10.1454 16.7693 10.4758 16.8787 10.7739L18.463 15.0925L14.1444 16.6768C13.8462 16.7861 13.6932 17.1165 13.8026 17.4146C13.912 17.7127 14.2423 17.8658 14.5404 17.7564L19.3988 15.9741ZM0.26096 15.2363C0.151588 15.5344 0.304612 15.8647 0.602747 15.9741L5.46114 17.7564C5.75928 17.8658 6.08963 17.7128 6.199 17.4146C6.30837 17.1165 6.15535 16.7862 5.85721 16.6768L1.53864 15.0925L3.12291 10.7739C3.23228 10.4758 3.07926 10.1455 2.78112 10.0361C2.48299 9.92671 2.15264 10.0797 2.04327 10.3779L0.26096 15.2363ZM10.4075 0.157867C10.1829 -0.0666847 9.81884 -0.0666847 9.59429 0.157867L5.93501 3.81714C5.71046 4.0417 5.71046 4.40577 5.93501 4.63032C6.15956 4.85487 6.52363 4.85487 6.74818 4.63032L10.0009 1.37763L13.2536 4.63032C13.4781 4.85487 13.8422 4.85487 14.0667 4.63032C14.2913 4.40577 14.2913 4.0417 14.0667 3.81714L10.4075 0.157867ZM9.75919 11.6944L18.9591 15.956L19.4425 14.9126L10.2426 10.6509L9.75919 11.6944ZM9.7592 10.6509L0.559101 14.9126L1.04246 15.956L10.2426 11.6944L9.7592 10.6509ZM10.5759 11.1727V0.564453H9.42588V11.1727H10.5759Z'
        fill={active ? '#000' : '#767676'}
      />
    </svg>
  );
};

export default IconArrowTriple;
